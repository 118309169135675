<template>
  <div>
    <v-card class="ma-4 cardBoxShadow rounded-lg">
      <div class="d-flex justify-space-between align-center px-5">
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="getPayinDataFromApi('form')"
          lazy-validation
        >
          <div class="d-flex flex-wrap align-center">
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">From Date *</div>
              <v-menu
                v-model="dateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click:append="dateMenu1 = true"
                    class="apiKeyInput"
                    v-model="computedDateFormatted1"
                    outlined
                    persistent-hint
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="fromDateRules"
                    placeholder="Select the From Date"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  :show-current="true"
                  no-title
                  @input="handleToDate()"
                  :max="new Date().toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">From Time *</div>
              <input type="time" class="time-picker" step="1" :class="{'input-error': timeError_from && !from_time}" v-model="from_time">
              <p class="error-message">
                <span v-if="timeError_from && !from_time" >From Time is required</span></p>
            </div>
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">To Date *</div>
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click:append="dateMenu2 = true"
                    class="apiKeyInput"
                    v-model="computedDateFormatted2"
                    outlined
                    persistent-hint
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="toDateRules"
                    placeholder="Select the To Date"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  :show-current="true"
                  no-title
                  @input="dateMenu2 = false"
                  :max="getMaxToDate()"
                  :min="fromDate"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">To Time *</div>
              <input type="time" class="time-picker" step="1" :class="{'input-error': timeError_to && !to_time}" v-model="to_time">
              <p class="error-message">
                <span v-if="timeError_to && !to_time">To Time is required</span></p>
            </div>
 
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">User ID</div>
              <v-text-field
                outlined
                v-model="userID"
                dense
                height="40"
                autofocus
              ></v-text-field>
            </div>
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">Payment Method</div>
              <div>
                <v-select
                  dense
                  class="mr-3"
                  v-model="paymentMethod"
                  placeholder="Select User Type"
                  :items="payments"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </div>
            </div>
           
            <!-- Filter -->
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">ICCL / RMS / BO</div>
              <div>
                <v-select
                  dense
                  class="mr-3"
                  v-model="backOffice"
                  placeholder="Select User Type"
                  :items="backOfficeList"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </div>
            </div>
            <div class="px-4 pt-4">
              <div class="pb-1 fsize12">Status</div>
              <div>
                <v-select
                  dense
                  class="mr-3"
                  v-model="backOfficeStatus"
                  placeholder="Select User Type"
                  :items="backOfficeStatusList"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                >
                </v-select>
              </div>
            </div>
            <!-- Filter END-->
            <div class="mr-8 d-flex align-end">
              <v-btn
                type="submit"
                height="40"
                depressed
                color="primary"
                class="text-capitalize fsize12 mt-3"
                :loading="loading"
                :depressed="loading"
                >Submit</v-btn
              >
              <v-btn
          v-if="getPayinData.length > 0"
          height="32"
          @click="getPayinDataFromApi('download')"
          depressed
          min-width="fit-content"
          color="primary"
          class="d-flex items-center text-capitalize ml-4"
          :loading="getDownloadLoader"
                :depressed="getDownloadLoader"
        >
          Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
        </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </v-card>
    <v-data-table
      v-if="getPayinData.length != 0"
      :headers="headers"
      v-bind:items="getPayinData"
      :search="search"
      :value="getPayinData"
      class="my-2 mx-4"
      id="table"
      item-key="name"
      single-select
      :page.sync="pageNo"
      :items-per-page.sync="pageSize"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20],
      }"
      @update:page="onPageChange"
      @update:items-per-page="onItemsPerPageChange"
      :server-items-length="getPaginationCount"
    >
    <template v-slot:header.userId="{ header }">
      <div :class="header.class">
      {{ header.text }}
    </div>
  </template>
    <template v-slot:header.createdOn="{ header }">
      <div :class="header.class">
      {{ header.text }}
    </div>
  </template>
  <template v-slot:header.transactionId="{ header }">
      <div :class="header.class">
      {{ header.text }}
    </div>
  </template>
  <template v-slot:header.amount="{ header }">
      <div :class="header.class">
      {{ header.text }}
    </div>
  </template>
  <template v-slot:header.segment="{ header }">
      <div :class="header.class">
      {{ header.text }}
    </div>
  </template>
  <template v-slot:header.paymentMethod="{ header }">
      <div :class="header.class">
      {{ header.text }}
    </div>
  </template>
  <template v-slot:header.iccl="{ header }">
    <div :class="header.class">
              <v-checkbox  hide-details  class="ma-0 pa-0" v-model="headerCheckbox.iccl" v-if="isICCL" @change="toggleSelectAll('iccl')"
                   > </v-checkbox>   <span style="width: 85px;">{{ header.text }}</span>
          </div>
  </template>
  <template v-slot:header.rms="{ header }">
    <div :class="header.class">
              <v-checkbox  hide-details  class="ma-0 pa-0" v-if="isRMS" v-model="headerCheckbox.rms" @change="toggleSelectAll('rms')"
                   > </v-checkbox>   <span style="width: 85px;">{{ header.text }}</span>
          </div>
  </template>
  <template v-slot:header.bo="{ header }">
    <div :class="header.class">
              <v-checkbox  hide-details class="ma-0 pa-0" v-if="isBO" v-model="headerCheckbox.bo" @change="toggleSelectAll('bo')"
                   > </v-checkbox>   <span style="width: 85px;">{{ header.text }}</span>
          </div>
  </template>
 
 
    <!-- thable body  -->
      <template v-slot:item="props">
        <tr>
          <td class="text-center">{{ props.item.createdOn ? props.item.createdOn : "-" }}</td>
          <td class="text-center">{{ props.item.userId }}</td>
          <td class="text-center">{{ props.item.orderId }}</td>
          <td class="text-center">
            {{ props.item.amount ? Number(props.item.amount).toFixed(2) : "" }}
          </td>
          <td class="text-center">
            {{ props.item.exchSeg }}
          </td>
          <td class="text-center">
            {{ props.item.paymentMethod }}
          </td>
          <td>
            <div
              class="d-flex text-center"
              style="align-items: center; justify-content: center"
            >
              <div class="checkboxWidth">
                <v-checkbox @change="updateCheckboxCount('iccl')" v-if="props.item.icclStatus == '2' || props.item.icclStatus == '3'"
                  v-model="props.item.icclCheckboxValue"
                  :class="
                    props.item.icclStatus == '2' || props.item.icclStatus == '3'
                      ? ''
                      : 'visibilityhidden'
                  "
                ></v-checkbox>
              </div>
 
              <v-btn
                height="24"
                width="80"
                depressed
                :color="getStatusColor(props.item.icclStatus)"
                class="text-capitalize fsize12 white--text ml-2"
              >
                <!-- {{ props.item.icclStatus == "1" ? "Success" : "Failed" }} -->
                {{ getStatus(props.item.icclStatus) }}
              </v-btn>
            </div>
          </td>
          <td>
            <div
              class="d-flex text-center"
              style="align-items: center; justify-content: center"
            >
              <div class="checkboxWidth">
                <v-checkbox @change="updateCheckboxCount('rms')" v-if="props.item.rmsStatus == '2' || props.item.rmsStatus == '3'"
                  v-model="props.item.rmsCheckboxValue"
                  :class="
                    props.item.rmsStatus == '2' || props.item.rmsStatus == '3'
                      ? ''
                      : 'visibilityhidden'
                  "
                ></v-checkbox>
              </div>
 
              <v-btn
                height="24"
                width="80"
                depressed
                :color="getStatusColor(props.item.rmsStatus)"
                class="text-capitalize fsize12 white--text ml-2"
              >
                {{ getStatus(props.item.rmsStatus) }}
              </v-btn>
            </div>
          </td>
          <td>
            <div
              class="d-flex text-center"
              style="align-items: center; justify-content: center"
            >
              <div class="checkboxWidth">
                <v-checkbox @change="updateCheckboxCount('bo')" v-if=" props.item.boStatus == '2' || props.item.boStatus == '3'"
                  v-model="props.item.boCheckboxValue"
                  :class="
                    props.item.boStatus == '2' || props.item.boStatus == '3'
                      ? ''
                      : 'visibilityhidden'
                  "
                ></v-checkbox>
              </div>
 
              <v-btn
                height="24"
                width="80"
                depressed
                :color="getStatusColor(props.item.boStatus)"
                class="text-capitalize fsize12 white--text"
                >{{ getStatus(props.item.boStatus) }}</v-btn
              >
            </div>
          </td>
        </tr>
      </template>
      <!-- table footer  -->
      <template v-slot:body.append>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-center pl-14">
            <v-btn
              v-if="icclCount > 0"
              height="32"
              width="100"
              depressed
              color="primary"
              @click="repushIccl()"
              class="text-capitalize fsize16"
              >Repush ({{ icclCount }})</v-btn
            >
          </th>
          <th class="text-center pl-14">
            <v-btn
              v-if="rmsCount > 0"
              height="32"
              width="100"
              depressed
              color="primary"
              @click="repushRms()"
              class="text-capitalize fsize16"
              >Repush ({{ rmsCount }})</v-btn
            >
          </th>
          <th class="text-center pl-14">
            <v-btn
              v-if="boCount > 0"
              height="32"
              width="100"
              depressed
              color="primary"
              @click="repushBo()"
              class="text-capitalize fsize16"
              >Repush ({{ boCount }})</v-btn
            >
          </th>
        </tr>
      </template>
    </v-data-table>
 
    <v-dialog v-model="dialog" max-width="600">
      <v-card class="pa-2">
        <div class="my-3">
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">User ID:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.userId }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">Voucher No:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{
                getCurrentRowData.voucherNo ? getCurrentRowData.voucherNo : "NA"
              }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">Account No:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.accountNo }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">Count:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.count }}
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">RMS Status:</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.rmsStatus == "1" ? "Success" : "Failed" }}
              <v-btn
                @click="retryrmsStatus()"
                :loading="getrmsUpdateLoader"
                depressed
                height="26"
                width="60"
                class="text-capitalize fsize12 mx-3"
                v-if="getCurrentRowData.rmsStatus == '0'"
                >Retry</v-btn
              >
            </div>
          </div>
          <div class="d-flex align-center mb-3">
            <div class="fsize12 secondaryColor">BO Status :</div>
            <div class="d-flex align-center fsize12 primaryColor mx-3">
              {{ getCurrentRowData.boStatus == "1" ? "Success" : "Failed" }}
              <v-btn
                @click="retryBoUpdate()"
                :loading="getBoUpdateLoader"
                depressed
                height="26"
                width="60"
                class="text-capitalize fsize12 mx-3"
                v-if="getCurrentRowData.boStatus == '0'"
                >Retry</v-btn
              >
            </div>
          </div>
        </div>
        <div
          class="my-2 border pa-2 rounded-lg"
          dense
          v-for="(i, idx) in getPayinResponse"
          v-if="getPayinResponse.length != 0"
        >
          <div class="fsize12">
            <div class="secondaryColor">Request :</div>
            <div class="primaryColor resp-data">
              <json-viewer :value="i.request" sort></json-viewer>
            </div>
          </div>
          <div class="fsize12">
            <div class="secondaryColor">Response :</div>
            <div class="primaryColor resp-data">
              <json-viewer :value="i.response" sort></json-viewer>
            </div>
          </div>
          <div class="fsize12">
            <div class="secondaryColor">Error Message :</div>
            <div class="primaryColor resp-data">
              <json-viewer
                :value="i.errorMsg ? i.errorMsg : 'NA'"
                sort
              ></json-viewer>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import JsonViewer from "vue-json-viewer";
import commonMixins from "../../mixins/commonFunctions";
Vue.use(JsonViewer);
export default {
  data() {
    return {
      valid: true,
      dateMenu1: false,
      dateMenu2: false,
      // fromDate: null,
      // toDate: null,
      fromDate: null,
      toDate: null,
      search: "",
      payinData: [],
      dialog: false,
 
      fromAmount: "",
      toAmount: "",
      paymentStatus: "",
      userID: "",
      paymentMethod: "",
      exchangeSeg: "",
      paymentMethod: "",
      paginationCount: 2,
      page: 1,
      fromDateRules: [(v) => !!v || "From Date is required"],
      toDateRules: [(v) => !!v || "To Date is required"],
      fromAmountRules: [(v) => !!v || "From Amount is required"],
      toAmountRules: [(v) => !!v || "To Amount is required"],
      toTimeRules: [(v) => !!v || "To Time is required"],
      fromTimeRules: [(v) => !!v || "From Time is required"],
      payments: ["HDFC PAYMENT", "UPI", "Net"],
      headers: [
      { text: "Date", align: "center", value: "createdOn",sortable: false,},
        { text: "User Id", align: "center", value: "userId",sortable: false,},
        { text: "Transaction ID", align: "center", value: "transactionId",sortable: false,},
        { text: "Amount", align: "center", value: "amount",sortable: false,},
        { text: "Segment", align: "center", value: "segment",sortable: false,},
        { text: "Payment Method", align: "center", value: "paymentMethod",sortable: false,},
        { text: "ICCL", align: "center ", class: 'flex_head', value: "iccl",sortable: false,},
        { text: "RMS", align: "center", value: "rms" ,class: 'flex_head',sortable: false,},
        { text: "BO", align: "center", value: "bo", class: 'flex_head',sortable: false,},
        // Add other headers if needed
      ],
      pageNo: 1,
      pageSize: 10,
      from_time:'',
      to_time:'',
      timeError_to: false,
      timeError_from: false,
      headerCheckbox: {
        iccl: false,
        rms: false,
        bo: false,
      },
      icclCount: 0,
      rmsCount: 0,
      boCount: 0,
      backOfficeList : [
        { text:'All', value:'All'},
        { text:'ICCL', value:'ICCL'},
        { text:'RMS', value:'RMS'},
        { text:'BO', value:'BO'},
      ],
      backOffice: "All",
      backOfficeStatusList : [
        { text:'All', value:'All'},
        { text:'Pending', value:'0'},
        { text:'Success', value:'1'},
        { text:'Failed', value:'2'},
        { text:'Not Pushed', value:'3'},
      ],
      backOfficeStatus: "All",
    };
  },
  mixins: [commonMixins],
  computed: {
    ...mapGetters("payin", [
      "getPayinData",
      "getPayinResponse",
      "getrmsUpdateLoader",
      "getBoUpdateLoader",
      "getCurrentRowData",
      "getPayinLastPayload",
      "getIsRmsSwitch",
      "getIsRmsBoSwitch",
      "getPaginationCount",
      "getPayinDownloadData",
      "getDownloadLoader"
    ]),
    ...mapGetters({
      loading: "getLoader",
    }),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate, '-');
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.VutifyFormatDate(this.toDate, '-');
      },
      set() {},
    },
    isRmsSwitch: {
      get() {
        return this.getIsRmsSwitch;
      },
      set(value) {
        this.$store.commit("payin/setIsRmsSwitch", value);
      },
    },
    isRmsBoSwitch: {
      get() {
        return this.getIsRmsBoSwitch;
      },
      set(value) {
        this.$store.commit("payin/setIsRmsBoSwitch", value);
      },
    },
    isICCL(){
      return this.getPayinData.some(item => item.icclStatus == '2' || item.icclStatus == '3')
    },
    isRMS(){
      return this.getPayinData.some(item => item.rmsStatus == '2' || item.rmsStatus == '3')
    },
    isBO(){
      return this.getPayinData.some(item => item.boStatus == '2' || item.boStatus == '3')
    }
  },
  methods: {
    toggleSelectAll(type) {
      const isChecked = this.headerCheckbox[type];
      this.getPayinData.forEach(item => {
        if (type == 'iccl') {
          if (item.icclStatus == '2' || item.icclStatus == '3') {
            item.icclCheckboxValue = isChecked;
          }
        } else if (type == 'rms') {
          if (item.rmsStatus == '2' || item.rmsStatus == '3') {
            item.rmsCheckboxValue = isChecked;
          }
        } else if (type == 'bo') {
          if (item.boStatus == '2' || item.boStatus == '3') {
            item.boCheckboxValue = isChecked;
          }
        }
      });
      this.updateCheckboxCount(type);
    },
    updateCheckboxCount(type) {
      const checkedItems = this.getPayinData.filter(item => {
        if (type == 'iccl') return item.icclCheckboxValue && (item.icclStatus == '2' || item.icclStatus == '3');
        if (type == 'rms') return item.rmsCheckboxValue && (item.rmsStatus == '2' || item.rmsStatus == '3');
        if (type == 'bo') return item.boCheckboxValue && (item.boStatus == '2' || item.boStatus == '3');
      });
 
      this[`${type}Count`] = checkedItems.length;
 
      this.headerCheckbox[type] = this.getPayinData.every(item => {
        if (type == 'iccl') return item.icclStatus == '2' || item.icclStatus == '3' ? item.icclCheckboxValue : true;
        if (type == 'rms') return item.rmsStatus == '2' || item.rmsStatus == '3' ? item.rmsCheckboxValue : true;
        if (type == 'bo') return item.boStatus == '2' || item.boStatus == '3' ? item.boCheckboxValue : true;
      });
    },
    async getPayinDataFromApi(from) {
      if(from && from == 'form'){
        this.pageNo = 1
      }
      this.timeError_to = this.timeError_from = false
      if (this.$refs.form.validate() && this.from_time && this.to_time) {
        let json = {
          // "paymentMethod": this.paymentStatus ? this.paymentStatus : '%',
          fromDate: `${this.fromDate} ${this.from_time}`,
          toDate: `${this.toDate} ${this.to_time}`,
          // "offSet": 0,
          userId: this.userID ? this.userID : "",
          // "fromAmount": this.fromAmount ? this.fromAmount : '0',
          // "toAmount": this.toAmount ? this.toAmount : '1000000000',
          // "exchSeg": this.exchangeSeg ? this.exchangeSeg : "%",
          paymentMethod: this.paymentMethod ? this.paymentMethod : "",
          tpp :this.backOffice == 'All' ? '' : this.backOffice ,
          status: this.backOfficeStatus == 'All' ? '' : this.backOfficeStatus
        };
       
       
        await this.$store.dispatch("payin/getPayinData", {
          json: json,
          page: from && from == 'download' ? 0 : Number(this.pageNo) - 1,
          size: from && from == 'download' ? this.getPaginationCount : this.pageSize,
          from:from
        }).finally(()=>{
          if(from && from == 'download'){
            this.getPayinDownloadData.forEach((el)=>{
              el.icclStatus = this.getStatus(el.icclStatus)
              el.rmsStatus = this.getStatus(el.rmsStatus)
              el.boStatus = this.getStatus(el.boStatus)
            })
            this.downloadUnicodeCSV(this.getPayinDownloadData, 'payindata')
          }else{
          this.headerCheckbox.iccl = false
          this.headerCheckbox.rms = false
          this.headerCheckbox.bo = false
          this.icclCount= 0,
          this.rmsCount= 0,
          this.boCount= 0
          }
        })
      }else{
        if(!this.to_time){
          this.timeError_to = true
        }
        if(!this.from_time){
          this.timeError_from = true
        }
      }
    },
    getPayinResponseFromApi(data) {
      let json = {
        orderId: data.orderId,
        userId: data.userId,
      };
      this.$store.dispatch("payin/getPayinResponse", json);
    },
 
    handleStatus(data) {
      this.dialog = true;
      this.$store.commit("payin/setCurrentRowData", data);
      this.getPayinResponseFromApi(data);
    },
    retry() {},
    async retryrmsStatus() {
      let json = {
        orderId: this.getCurrentRowData?.orderId,
        userId: this.getCurrentRowData?.userId,
      };
      await this.$store.dispatch("payin/payinRmsRetry", json);
      await this.$store.dispatch(
        "payin/getPayinData",
        this.getPayinLastPayload
      );
      if (this.getPayinData?.length) {
        let crow = "";
        this.getPayinData.forEach((el) => {
          if (el.orderId == json.orderId && el.userId == json.userId) {
            crow = el;
          }
        });
        this.$store.commit("payin/setCurrentRowData", crow);
      }
    },
    async retryBoUpdate() {
      let json = {
        orderId: this.getCurrentRowData?.orderId,
        userId: this.getCurrentRowData?.userId,
      };
      await this.$store.dispatch("payin/payinBoRetry", json);
      await this.$store.dispatch(
        "payin/getPayinData",
        this.getPayinLastPayload
      );
      if (this.getPayinData?.length) {
        let crow = "";
        this.getPayinData.forEach((el) => {
          if (el.orderId == json.orderId && el.userId == json.userId) {
            crow = el;
          }
        });
        this.$store.commit("payin/setCurrentRowData", crow);
      }
    },
    async handleChangeRms(e) {
      if (this.isRmsSwitch) {
        this.$store.dispatch("payin/disableRms");
      } else {
        this.$store.dispatch("payin/enableRms");
      }
    },
    async handleChangeBoRMS(e) {
      if (this.isRmsBoSwitch) {
        this.$store.dispatch("payin/disableBORms");
      } else {
        this.$store.dispatch("payin/enableBORms");
      }
    },
    getRepushIcclStatus() {
      let bool = false;
      this.getPayinData.forEach((el) => {
        el.icclCheckboxValue ? (bool = true) : "";
      });
      return bool;
    },
    getRepushRmsStatus() {
      let bool = false;
      this.getPayinData.forEach((el) => {
        el.rmsCheckboxValue ? (bool = true) : "";
      });
      return bool;
    },
    getRepushBoStatus() {
      let bool = false;
      this.getPayinData.forEach((el) => {
        el.boCheckboxValue ? (bool = true) : "";
      });
      return bool;
    },
    repushRms() {
      let json = [];
      this.getPayinData.forEach((el) => {
        if (el.rmsCheckboxValue) {
          let obj = {
            userId: el.userId ? el.userId : "",
 
            exchSegment: el.exchSeg ? el.exchSeg : "",
 
            orderId: el.orderId ? el.orderId : "",
 
            amount: el.amount ? el.amount : "",
 
            bnkAccNum: el.accountNum ? el.accountNum : "",
 
            paymentId: el.receiptId ? el.receiptId : "",
 
            paymentMethod: el.paymentMethod ? el.paymentMethod : "",
          };
          json.push(obj);
        }
      });
      this.$store.dispatch("payin/repushRms", json).finally(() => {
        this.getPayinDataFromApi('repush')  
      });
    },
    repushIccl() {
      let json = [];
      this.getPayinData.forEach((el) => {
        if (el.icclCheckboxValue) {
          let obj = {
            userId: el.userId ? el.userId : "",
 
            exchSegment: el.exchSeg ? el.exchSeg : "",
 
            orderId: el.orderId ? el.orderId : "",
 
            amount: el.amount ? el.amount : "",
 
            bnkAccNum: el.accountNum ? el.accountNum : "",
 
            paymentId: el.receiptId ? el.receiptId : "",
 
            paymentMethod: el.paymentMethod ? el.paymentMethod : "",
          };
          json.push(obj);
        }
      });
      this.$store.dispatch("payin/repushIccl", json).finally(() => {
        this.getPayinDataFromApi('repush')  
      });
    },
    repushBo() {
      let json = [];
      this.getPayinData.forEach((el) => {
        if (el.boCheckboxValue) {
          let obj = {
            userId: el.userId ? el.userId : "",
 
            exchSegment: el.exchSeg ? el.exchSeg : "",
 
            orderId: el.orderId ? el.orderId : "",
 
            amount: el.amount ? el.amount : "",
 
            bnkAccNum: el.accountNum ? el.accountNum : "",
 
            paymentId: el.receiptId ? el.receiptId : "",
 
            paymentMethod: el.paymentMethod ? el.paymentMethod : "",
          };
          json.push(obj);
        }
      });
      this.$store.dispatch("payin/repushBo", json).finally(() => {
        this.getPayinDataFromApi('repush')  
      });
    },
    getStatus(key) {
      if (key == "1") {
        return "Success";
      } else if (key == "2") {
        return "Failed";
      } else if (key == "3") {
        return "Not Pushed";
      } else if (key == "0") {
        return "Pending";
      }
    },
    getStatusColor(key) {
      if (key == "1") {
        return "#62ab74";
      } else if (key == "2") {
        return "#da4e4e";
      } else if (key == "3") {
        return "#808080";
      } else if (key == "0") {
        return "#FFDE00";
      }
    },
    getMaxToDate() {
      let currentDate = new Date();
      let maxDate = new Date(this.fromDate);
      maxDate.setDate(maxDate.getDate() + 6); // Add 7 days
      if (maxDate > currentDate) {
        return currentDate.toISOString().substr(0, 10);
      } else {
        return maxDate.toISOString().substr(0, 10);
      }
    },
    handleToDate() {
      this.dateMenu1 = false;
      let currentDate = new Date();
      let maxDate = new Date(this.fromDate);
      maxDate.setDate(maxDate.getDate() + 6); // Add 7 days
      if (maxDate > currentDate) {
        this.toDate = currentDate.toISOString().substr(0, 10);
      } else {
        this.toDate = maxDate.toISOString().substr(0, 10);
      }
    },
    onPageChange(newPage) {
      console.log(newPage, "pafe change");
 
      this.pageNo = newPage;
      this.getPayinDataFromApi('page');
    },
    onItemsPerPageChange(newItemsPerPage) {
      this.pageSize = newItemsPerPage;
      this.getPayinDataFromApi('perPage');
    },
  },
  created() {
    let dfaultTime = new Date()
    dfaultTime.setHours(9);
    dfaultTime.setMinutes(0);
    dfaultTime.setSeconds(0);
    
    this.from_time = this.formattingDate(dfaultTime, 'T')
    this.to_time = this.formattingDate(new Date() , 'T')
    this.fromDate = this.toDate = new Date().toISOString().substr(0, 10)
    this.$store.commit("payin/setPayinData", []);
  },
  mounted(){
    this.getPayinDataFromApi('form')
  }
};
</script>
<style scoped>
.checkboxWidth{
 min-width: 30px !important
}
.footer-div {
  display: table-footer-group;
  vertical-align: middle;
  unicode-bidi: isolate;
  border-color: inherit;
}
 
.time-picker {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  width: 200px;
  margin-bottom: 0px !important;
}
 
.input-error {
  border: 2px solid #FF5252;
}
 
.error-message {
  color: #FF5252;
  font-size: 12px;
  height: 14px;
  padding-top: 2px;
  margin-bottom: 12px !important;
}
 
.flex_head{
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
}
 
</style>
