<template>
  <div>
    <!-- Limit UPLOAD -->
    <v-card class="ma-4 cardBoxShadow rounded-lg">
      <v-row no-gutters class="px-4 py-10">
        <v-col cols="12" sm="12" md="6" lg="3">
          <div class="fsize14 font-weight-bold primaryColor pb-4">
            File Upload
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="5">
          <!-- <v-radio-group
            row
            v-model="whichOrigin"
            @change="resetAll"
            class="mb-4 pt-0 mt-0"
          >
            <v-radio label="Kambala" value="Kambala"></v-radio>
          </v-radio-group> -->
          <div class="primaryColor fsize14 pb-1">Cash Limit File</div>
          <v-file-input
            :rules="[cashLimitFileSizeRule]"
            :show-size="true"
            class="fileInput"
            max-width="450px"
            autofocus
            id="file"
            type="file"
            placeholder="Select file (.csv File only)"
            ref="cashLimitFile"
            :multiple="false"
            v-model="cashLimitFile"
            accept=".csv"
            outlined
            dense
            append-icon="mdi-folder-open"
            @change="uploadDoc(cashLimitFile, 'cash')"
            @click:clear="uploadDoc('clear')"
            prepend-icon=""
          ></v-file-input>
          <div class="primaryColor fsize14 pb-1">Currency Limit File</div>
          <v-file-input
            :rules="[currencyLimitFileSizeRule]"
            :show-size="true"
            class="fileInput"
            max-width="450px"
            id="file"
            type="file"
            placeholder="Select file (.csv File only)"
            ref="CurrencyLimitFile"
            :multiple="false"
            v-model="currencyLimitFile"
            accept=".csv"
            outlined
            dense
            append-icon="mdi-folder-open"
            @change="uploadDoc(currencyLimitFile, 'currency')"
            @click:clear="uploadDoc('clear')"
            prepend-icon=""
          ></v-file-input>
          <div class="primaryColor fsize14 pb-1">Futures Limit File</div>
          <v-file-input
            :rules="[futuresLimitFileSizeRule]"
            :show-size="true"
            class="fileInput"
            max-width="450px"
            id="file"
            type="file"
            placeholder="Select file (.csv File only)"
            ref="futuresLimitFile"
            :multiple="false"
            v-model="futuresLimitFile"
            accept=".csv"
            outlined
            dense
            append-icon="mdi-folder-open"
            @change="uploadDoc(futuresLimitFile, 'futures')"
            @click:clear="uploadDoc('clear')"
            prepend-icon=""
          ></v-file-input>
          <div class="primaryColor fsize14 pb-1">Debit File</div>
          <v-file-input
            :rules="[debitFileRule]"
            :show-size="true"
            class="fileInput"
            max-width="450px"
            id="file"
            type="file"
            placeholder="Select file (.csv File only)"
            ref="debitFile"
            @click:clear="uploadDoc('clear')"
            @change="uploadDoc(debitFile, 'debit')"
            :multiple="false"
            v-model="debitFile"
            accept=".csv"
            outlined
            dense
            append-icon="mdi-folder-open"
            prepend-icon=""
          ></v-file-input>
        </v-col>
      </v-row>
    </v-card>

    <div class="mt-5 pa-4 d-flex justify-end">
      <v-btn
        height="40"
        width="96"
        depressed
        @click="clearFiles()"
        outlined
        color="grey--text"
        class="mr-3 text-capitalize"
        >Cancel</v-btn
      >
      <v-btn
        height="40"
        width="96"
        depressed
        :loading="loading"
        :disabled="loading"
        color="primary"
        @click="insertFile()"
        class="text-capitalize fsize16"
        >Submit</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "limitFileUpload",
  data: () => ({
    cashLimitFile: null,
    currencyLimitFile: null,
    futuresLimitFile: null,
    debitFile: null,
  }),
  methods: {
    cashLimitFileSizeRule() {
      if (this.limitFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },
    currencyLimitFileSizeRule() {
      if (this.limitFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },
    futuresLimitFileSizeRule() {
      if (this.limitFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },
    debitFileRule() {
      if (this.debitFile?.size > 30 * 1024 * 1024) {
        return "File size exceeds the limit (30MB).";
      } else {
        return true;
      }
    },

    async insertFile() {
      if (
        this.cashLimitFileSizeRule() == "File size exceeds the limit (30MB)." &&
        this.currencyLimitFileSizeRule() ==
          "File size exceeds the limit (30MB)." &&
        this.futuresLimitFileSizeRule() == "File size exceeds the limit (30MB)."
      )
        return;
      if (this.debitFileRule() == "File size exceeds the limit (30MB).") return;
      if (
        this.currencyLimitFile != null ||
        this.cashLimitFile != null ||
        this.futuresLimitFile != null ||
        this.debitFile != null
      ) {
        if (this.debitFile) {
          this.$store.dispatch("controls/insertDebitFile");
        } else {
          this.$store.dispatch("controls/insertLimitFile");
        }
      } else {
        this.$store.commit("SET_SNAKBAR", {
          msg: "Please Upload All Documents",
          show: true,
          color: "red lighten-1",
          timeout: 2500,
        });
      }
    },
    uploadDoc(file, type) {
      if (file == "clear" || file == null) return;
      if (
        this.cashLimitFileSizeRule() == "File size exceeds the limit (30MB)." &&
        type == "cash"
      )
        return;
      if (
        this.currencyLimitFileSizeRule() ==
          "File size exceeds the limit (30MB)." &&
        type == "currency"
      )
        return;
      if (
        this.futuresLimitFileSizeRule() ==
          "File size exceeds the limit (30MB)." &&
        type == "futures"
      )
        return;
      if (
        this.debitFileRule() == "File size exceeds the limit (30MB)." &&
        type == "debit"
      )
        return;

      let formData = new FormData();
      formData.append("file", file);
      let exch = type.toUpperCase();

      formData.append("exchange", exch);

      let payload = {
        formData: formData,
      };

      if (type == "debit") {
        this.$store.dispatch("controls/uploadDebitFile", payload);
      } else if (type == "cash" || type == "currency" || type == "futures") {
        this.$store.dispatch("controls/uploadLimitFile", payload);
      }
    },
    clearFiles() {
      this.cashLimitFile =
        this.currencyLimitFile =
        this.futuresLimitFile =
        this.debitFile =
          null;
    },
  },
  computed: {
    ...mapState("payments", ["confirmationDialog"]),
    ...mapGetters({
      loading: "getLoader",
    }),
  },
};
</script>
