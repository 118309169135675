<template>
  <div>
    <div class="d-flex align-center px-4 py-2 mt-2">
      <div class="w-100">
        <div class="font-weight-bold primaryColor">
          Allocations
          <v-progress-circular
            class="ml-2"
            indeterminate
            v-if="getLoader"
            size="18"
            :width="2"
            color="black"
          ></v-progress-circular>
        </div>
        <div class="pt-3 d-flex">
          <v-slide-group v-model="tabSelect" mandatory>
            <v-slide-item
              v-for="n in tabs"
              :key="n"
              :value="n"
              v-slot="{ active, toggle }"
            >
              <span
                @click="
                  $router
                    .push({ path: 'allocations', query: { tab: n } })
                    .catch(() => {})
                "
              >
                <v-btn
                  :title="n"
                  depressed
                  height="26"
                  class="fsize12 text-capitalize mr-2"
                  @click="toggle"
                  :color="active ? 'primary' : 'grey lighten-3'"
                  >{{ n }}</v-btn
                >
              </span>
            </v-slide-item>
          </v-slide-group>
          <v-spacer></v-spacer>
        </div>
      </div>
    </div>
    <payin v-if="tabSelect == 'Payment Status'" />

    <controlsComponent v-if="tabSelect == 'Controls'" />
    <LimitFileUpload v-if="tabSelect == 'File Upload'"></LimitFileUpload>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import controlsComponent from "./controls.vue";
import LimitFileUpload from "./limitFileUpload.vue";
import payin from "./allocationsPayin.vue";


export default {
  name: "allocations",

  data: () => ({
    tabs: ["Payment Status", "File Upload", "Controls"],
    tabSelect: "",
    search: "",
  }),
  components: {
    controlsComponent,
    LimitFileUpload,
    payin,

  },
  created() {
    this.tabSelect = this.$route.query.tab
      ? this.$route.query.tab
      : "Payment Status";
  },
  computed: {
    ...mapGetters(['getLoader']),
  },
};
</script>
